import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getCouponData } from "../../../actions/stripeAction";
import theme from "../../../util/theme";
import { COUPON_DISCOUNT_DATA, mobileSize } from "../../../util/variables";
import { AppLoadingButton, AppText, FlexDiv } from "../../ui";
import Loading from "../../ui/loading";
import { HiLockClosed } from "react-icons/hi";
import { SubscriptionType } from "../../../util/misc";

interface Props {
  couponId: string;
  handleClick: (e: any, subscriptionType: SubscriptionType) => void;
  loading: boolean;
  trial: boolean;
}

export const SubscriptionDiscount: React.FC<Props> = ({
  couponId,
  handleClick,
  loading,
  trial,
}) => {
  const [coupon, setCoupon] = useState<{
    amount_off: number;
    duration: string;
  } | null>(null);
  const [discountPercentage, setDiscountPercentage] = useState<number | null>(null);

  useEffect(() => {
    const getCoupon = async () => {
      try {
        const discountObj = COUPON_DISCOUNT_DATA.find(c => c.id === couponId);
        if (discountObj) {
          setCoupon({
            amount_off: discountObj.discount,
            duration: discountObj.duration,
          });
          return;
        }

        const couponRes = await getCouponData(couponId);
        setCoupon(couponRes);
      } catch (err) {
        console.error(err);
        navigate("/onboarding");
      }
    };

    getCoupon();
  }, [couponId]);

  useEffect(() => {
    if (coupon === null) {
      return;
    }

    setDiscountPercentage(Math.floor(100 - (99.99 - coupon.amount_off)));
  }, [coupon]);

  const renderPlanBody = () => {
    return trial ? (
      <FlexDiv direction="column" gap={72} mobileGap={40}>
        <div>
          <FlexDiv justify="space-between" align="center">
            <PlanText>
              <b>Annual Subscription</b>
            </PlanText>
            <AppText fontSize={20} color={theme.PRIMARY_COLOR} style={{ marginBottom: 0 }}>
              <b>$0</b>
            </AppText>
          </FlexDiv>
          <PlanText color={theme.SLATE_COLOR_2}>7-day free trial</PlanText>
          <PlanText color={theme.SLATE_COLOR_2}>
            After trial, <b>${99.99 - coupon?.amount_off}</b> for the first year
          </PlanText>
          <PlanText color={theme.PRIMARY_COLOR}>({discountPercentage}% off)</PlanText>
        </div>
        <div>
          <AppText
            textAlign="center"
            fontSize={16}
            mobileFontSize={14}
            color={theme.SLATE_COLOR_2}
            style={{ marginBottom: 16 }}
          >
            You won&apos;t be charged until the end of your trial. Cancel anytime.
            {/* ${99.99 - amountOff} for the first year.
            <br />
            Then $99.99 after. */}
          </AppText>
          <FlexDiv align="center" justify="center" gap={4}>
            <AppText
              fontSize={12}
              mobileFontSize={11}
              fontWeight={600}
              color="#D2D5D8"
              style={{ margin: 0 }}
            >
              All transactions are secure{" "}
            </AppText>
            <HiLockClosed color="#D2D5D8" size={12} />
          </FlexDiv>
        </div>
        <DisplayDiv desktop>
          <AppLoadingButton
            disabled={loading}
            type="button"
            loading={loading}
            style={{ margin: "0 auto" }}
            onClick={e => handleClick(e, "coupon")}
          >
            Continue
          </AppLoadingButton>
          {/* <ViewAllPlanText
                  onClick={async () => navigate(`/subscription?page=Detail&cid=${couponId}`)}
                >
                  View all plans
                </ViewAllPlanText> */}
        </DisplayDiv>
      </FlexDiv>
    ) : (
      <FlexDiv direction="column" gap={72} mobileGap={40}>
        <DescriptionGrid>
          <FlexDiv direction="column">
            <PlanText>
              <b>Annual Subscription</b>
            </PlanText>
            <PlanText color={theme.SLATE_COLOR_2}>{discountPercentage}% Discount</PlanText>
          </FlexDiv>
          <FlexDiv direction="column" align="flex-end">
            <PlanText>
              <s>$99.99</s>
            </PlanText>
            <AppText fontSize={20} color={theme.PRIMARY_COLOR}>
              <b>${99.99 - coupon?.amount_off}</b>
            </AppText>
          </FlexDiv>
        </DescriptionGrid>
        <div>
          {coupon?.duration === "once" ? (
            <AppText
              textAlign="center"
              fontSize={16}
              mobileFontSize={14}
              color={theme.SLATE_COLOR_2}
              style={{ marginBottom: 16 }}
            >
              ${99.99 - coupon?.amount_off} for the first year.
              <br />
              Then $99.99 after.
            </AppText>
          ) : (
            <AppText
              textAlign="center"
              fontSize={16}
              mobileFontSize={14}
              color={theme.SLATE_COLOR_2}
              style={{ marginBottom: 16 }}
            >
              ${99.99 - coupon?.amount_off} forever.
            </AppText>
          )}
          <FlexDiv align="center" justify="center" gap={4}>
            <AppText
              fontSize={12}
              mobileFontSize={11}
              fontWeight={600}
              color="#D2D5D8"
              style={{ margin: 0 }}
            >
              All transactions are secure{" "}
            </AppText>
            <HiLockClosed color="#D2D5D8" size={12} />
          </FlexDiv>
        </div>
        <DisplayDiv desktop>
          <AppLoadingButton
            disabled={loading}
            type="button"
            loading={loading}
            style={{ margin: "0 auto" }}
            onClick={e => handleClick(e, "coupon")}
          >
            Continue
          </AppLoadingButton>
          {/* <ViewAllPlanText
                  onClick={async () => navigate(`/subscription?page=Detail&cid=${couponId}`)}
                >
                  View all plans
                </ViewAllPlanText> */}
        </DisplayDiv>
      </FlexDiv>
    );
  };

  return (
    <CouponContainer>
      <Title>Get Started</Title>
      {coupon === null ? (
        <Loading />
      ) : (
        <>
          <PlanContainer>
            <AppText
              color={theme.BLACK_COLOR}
              fontSize={24}
              mobileFontSize={20}
              style={{ marginBottom: 0 }}
            >
              <b>Your Plan</b>
            </AppText>
            <Hr />
            {renderPlanBody()}
          </PlanContainer>
          <DisplayDiv mobile>
            <AppLoadingButton
              disabled={loading}
              type="button"
              loading={loading}
              style={{ margin: "0 auto", border: "2px solid #fefefe" }}
              onClick={e => handleClick(e, "coupon")}
            >
              Continue
            </AppLoadingButton>
            {/* <ViewAllPlanText
              color={theme.WHITE_COLOR}
              onClick={async () => navigate("/subscription?page=Detail")}
            >
              View all plans
            </ViewAllPlanText> */}
          </DisplayDiv>
        </>
      )}
    </CouponContainer>
  );
};

const CouponContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 0 24px;
`;

const PlanContainer = styled.div`
  padding: 75px 40px;
  border-radius: 16px;
  background-color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    margin-bottom: 24px;
    padding: 32px 16px;
  }
`;

const Title = styled.h1`
  font-size: 35px;
  font-weight: 600;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    font-size: 24px;
    font-weight: 400;
  }
`;

const DescriptionGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
`;

const PlanText = styled(AppText as any)`
  margin: 0;
  font-size: 15px;
  line-height: 23px;
`;

const Hr = styled.hr`
  margin-top: 16px;
  margin-bottom: 48px;
  @media ${mobileSize} {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;

const DisplayDiv = styled.div<{ desktop?: boolean; mobile?: boolean }>`
  display: ${props => (props.desktop ? "block" : "none")};
  @media ${mobileSize} {
    display: ${props => (props.desktop ? "none" : "block")};
  }
`;

const ViewAllPlanText = styled.p<{ color?: string }>`
  height: 20px;
  width: 100%;
  margin: auto;
  margin-top: 24px;
  bottom: 18px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-align: center;
  color: ${props => props.color ?? "#2d7dd2"};
  cursor: pointer;
`;
